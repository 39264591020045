import React from 'react'
import './specials.scss'
import AutoMenu from './AutoMenu'
import EmailSubscribeForm from './EmailSubscribeForm'
import {Link} from 'react-router-dom'
import Stout from './stout'
import FormPrivateEvent from './FormPrivateEvent'

export default function () {
  return(
    <div className="page">
      <div
        className="page-header">

      <div className="text">
          <h1>We Do Private Events!</h1>
        {/* <p>preview our new menu.</p> */}
      </div>


      </div>
      <FormPrivateEvent></FormPrivateEvent>
        {/* <img 
        // style={{maxWidth: "1000px"}}
        src="https://afag.imgix.net/the-melt-on-wheels/menu.png?w=1600&auto=format" alt=""/> */}

      {/* <AutoMenu width={600} /> */}

    </div>
  )
}


function BBQSpecials () {

}
