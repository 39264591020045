
import * as Components from "./Katsu"

export default function getCustomComponent () {
  return Components
}

export function getShopId () {
  return "katsu"
}

