import React, { Component, PureComponent } from 'react';
import {NavLink, Link, Redirect} from 'react-router-dom'
import './index.scss'
import Catering from './Catering'
import OurStory from './OurStory'
import Specials from './Specials'
import NewMenu from './NewMenu'
import Event from './Event'
import Form from './Form'
import Beverage from './Beverage'
import Mothers from './Mothers'
import Wine from './Wine'
import LandingPage from './LandingPage'
import AutoMenu from './AutoMenu'
import Stout from './stout'

import './ThemeToggle/theme_night.scss'
import ThemeToggle from './ThemeToggle'


import CartBar from './CartBar'
export {CartBar as StickyTopBar}

export const customOrderAppPaths = []


class TwoGuysFromItalyGlendale extends Component {
  render() {
    return (
      <div className="twoguysfromitalyglendale">
      </div>
    );
  }
}


export const pages = [
  {path: "/order", label: "Order", },
  {path: "/menu", label: "Menu", component: NewMenu},
  {path: "/private-events", label: "Catering", component: Event},
]

export class SiteNav extends PureComponent {
  render() {

    return (
    <nav aria-label="secondary" className="pages-nav">
      <ul>
        {pages.map(({path, label, hidden}) => (
          hidden ? null : <li><NavLink className="page-link" to={path}>{label}</NavLink></li>
        ))}
      </ul>
      <ThemeToggle></ThemeToggle>
    </nav>
  )}
}

export class OrderingHeader extends PureComponent {
  render() {
    return (
      <div className="page">
        <div
          className="page-header">

        <div className="text">
            {/* <h1>Craft Beer Cellar Location Ordering</h1>
            <p>Pickup @ 907 Franklin St, Houston</p>
            <p>Delivery within 6 miles</p> */}
          {/* <p>Technical issue text message 737-333-1680</p> */}
        </div>


        </div>
        <Form></Form>
        <AutoMenu></AutoMenu>
        {/* <Form></Form> */}
      </div>
    );
  }
}

const sections = [
  {
    title: "Happy Hour",
    content: "Every Day 3pm-7pm. All Day Saturday",
    backgroundImage: ""
  }
]

// logo link: https://d2abh92eaqfsaa.cloudfront.net/image/upload/u7ryxhrj9dapgz8pqmny
export class Header extends PureComponent {
  render() {
    return (
      <div className="landing-page-wrapper">

      <LandingPage></LandingPage>










      </div>
    );
  }
}




const foodImages = [
  'https://afag.imgix.net/two-guys-from-italy-glendale/meat-balls-pasta.jpg?w=800&auto=compress',
  'https://afag.imgix.net/two-guys-from-italy-glendale/porchiuto.jpg?w=800&auto=compress',
  'https://afag.imgix.net/two-guys-from-italy-glendale/pizza.jpg?w=800&auto=compress',
  'http://www.glendaletwoguysfromitaly.com/img/carousel/home_bg_d.jpg',
  'http://www.glendaletwoguysfromitaly.com/img/carousel/home_bg_e.jpg'
]

export default TwoGuysFromItalyGlendale;
