import React from 'react'
import './specials.scss'
import AutoMenu from './AutoMenu'
import EmailSubscribeForm from './EmailSubscribeForm'
import {Link} from 'react-router-dom'
import Stout from './stout'

export default function () {
  return(
    <div className="page">
      <div
        className="page-header">

      <div className="text">
          <h1>The Menu</h1>
        {/* <p>preview our new menu.</p> */}
      </div>


        {/* <div className="logo-wrapper">
          <Stout className="stout-header"></Stout>
        </div> */}
      </div>

      <AutoMenu width={600} />

      <div
        style={{
          textAlign: 'center',
          paddingBottom: '1em'
        }}
        className="full-menu-link-wrapper">
        {/* <Link to="/order" className="button">Go to Full Menu</Link> */}
      </div>
    </div>
  )
}


function BBQSpecials () {

}
